export type TInsurance = { name: string; type: string; suggestion: boolean; iknr?: string };

export enum EInsuranceType {
  GKV = 'GKV',
  PKV = 'PKV',
}

export enum ERetiredOrFamilyInsuredOptions {
  NOT_RETIRED = 'NOT_RETIRED',
  FAMILY_INSURED = 'FAMILY_INSURED',
  PENSIONER = 'PENSIONER',
}

export enum EInsuranceFormInputTypes {
  INSURANCE_NUMBER = 'insuranceNumber',
  INSURANCE_IDENTIFICATION_NUMBER = 'insuranceIdentificationNumber',
  RETIRED_OR_FAMILY_INSURED = 'retiredOrFamilyInsured',
}

export type TInsuranceFormData = {
  [EInsuranceFormInputTypes.INSURANCE_NUMBER]: string;
  [EInsuranceFormInputTypes.INSURANCE_IDENTIFICATION_NUMBER]: string;
  [EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED]: ERetiredOrFamilyInsuredOptions;
};

export enum EAddressFormInputTypes {
  STREET = 'street',
  HOUSE_NUMBER = 'houseNumber',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  COUNTRY = 'country',
  CARE_OF = 'careOf',
}

export type TAddressFormData = {
  [EAddressFormInputTypes.STREET]: string;
  [EAddressFormInputTypes.HOUSE_NUMBER]: string;
  [EAddressFormInputTypes.ZIP_CODE]: string;
  [EAddressFormInputTypes.CITY]: string;
  [EAddressFormInputTypes.COUNTRY]: string;
  [EAddressFormInputTypes.CARE_OF]: string;
};

export enum EInsuranceSelectionError {
  INSURANCE_ERROR = 'INSURANCE_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}
