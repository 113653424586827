import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetZipCodeValidation, validateZipCode } from 'redux/Slices/address';
import { fetchInsurances } from 'redux/Slices/insurance';
import { TRootState, useAppDispatch } from 'redux/Store/store';
import { TPatientInsurance } from 'services/patient';

import {
  EAddressFormInputTypes,
  EInsuranceSelectionError,
  ERetiredOrFamilyInsuredOptions,
  TAddressFormData,
  TInsurance,
  TInsuranceFormData,
} from './type';

import BookingPageContainer from 'components/BookingPageContainer';

import InsuranceSelectionUi from './InsuranceSelection.ui';

import { trackEvent, trackPageView, trackSiteSearch } from 'utils/analyticsUtils';
import { AppRoutes } from 'utils/routeUtils';

import { useInsuranceSelectionTranslations } from './InsuranceSelection.translations';

import { INSURANCE_IDENTIFICATION_NUMBER, INSURANCE_NUMBER_PATTERN } from './constants';

const InsuranceSelectionData = () => {
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const [insuranceSuggestions, setInsuranceSuggestions] = useState<TInsurance[]>([]);

  const [selectedInsurance, setSelectedInsurance] = useState<TInsurance | null>(null);

  const [query, setQuery] = useState('');
  const [showSuggestionList, setShowSuggestionList] = useState(true);

  const [isInsuranceFormValid, setIsInsuranceFormValid] = useState(false);
  const [isAddressFormValid, setIsAddressFormValid] = useState(false);

  const [insuranceFormData, setInsuranceFormData] = useState<TInsuranceFormData>({
    insuranceNumber: '',
    insuranceIdentificationNumber: '',
    retiredOrFamilyInsured: ERetiredOrFamilyInsuredOptions.NOT_RETIRED,
  });
  const [addressFormData, setAddressFormData] = useState<TAddressFormData>({
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
    careOf: '',
  });

  const {
    isFetching: isFetchingInsurances,
    error: insuranceFetchingError,
    insurances,
  } = useSelector((state: TRootState) => state.insuranceReducer);

  const { error: zipCodeValidationError } = useSelector((state: TRootState) => state.addressReducer.zipCodeValidation);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translations = useInsuranceSelectionTranslations();

  useEffect(() => {
    trackPageView({ href: window.location.href });
    trackEvent({
      category: 'View',
      action: 'webapp:insuranceForm',
    });
    return () => {
      // Reset any validation errors which may have occurred when leaving
      dispatch(resetZipCodeValidation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedInsurance) return;
    if (selectedInsurance.type === 'GKV') {
      setIsContinueDisabled(!(isInsuranceFormValid && isAddressFormValid));
    } else {
      setIsContinueDisabled(!isAddressFormValid);
    }
  }, [isInsuranceFormValid, isAddressFormValid, selectedInsurance]);

  useEffect(() => {
    dispatch(fetchInsurances())
      .unwrap()
      .then((insurances) => {
        setInsuranceSuggestions(insurances.filter((insurance) => insurance.suggestion));
      })
      .catch((error) => {
        trackEvent({ category: 'View', action: 'InsuranceSelection-FetchInsurance-Error' });
      });
  }, [dispatch]);

  const handleSearchInput = (query: string) => {
    if (!insurances) return;

    if (!query.length) {
      setInsuranceSuggestions(insurances.filter((insurance) => insurance.suggestion));
      return;
    }

    const formattedQuery = query.toLowerCase();
    setInsuranceSuggestions(insurances.filter((insurance) => insurance.name.toLowerCase().includes(formattedQuery)));
    trackSiteSearch({ keyword: formattedQuery, category: 'webapp:insuranceSearch' });
  };

  const handleSelectInsurance = (insurance: TInsurance | null) => {
    setSelectedInsurance(insurance);

    if (insurance) {
      setQuery(insurance.name);
      // We would like to create funnels and segments based on the insurance type (PKV/GKV). Which insurance was selected is not that relevant.
      // Since matomo does not support proper segmenting of events, we therefore need to fire 2 separate events.
      trackEvent({
        category: 'Click',
        action: 'webapp:insuranceSelectType',
        name: insurance.type,
      });
      trackEvent({
        category: 'Click',
        action: 'webapp:insuranceSelected',
        name: insurance.name,
      });
    }
  };

  const handleGoBack = () => {
    trackEvent({ category: 'Click', action: 'webapp:insuranceSelection_back' });
    navigate(-1);
  };
  const handleContinue = () => {
    trackEvent({ category: 'Click', action: 'webapp:insuranceSelection_continue' });
    const insurance: TPatientInsurance = {
      type: selectedInsurance?.type ?? '',
      title: selectedInsurance?.name ?? '',
      number: insuranceFormData?.insuranceNumber,
      iknr: insuranceFormData?.insuranceIdentificationNumber || selectedInsurance?.iknr || '',
      status: insuranceFormData?.retiredOrFamilyInsured,
    };

    const address = {
      // We are storing the street number field with the street field in the backend
      street: `${addressFormData.street} ${addressFormData.houseNumber ?? ''}`,
      careOf: addressFormData.careOf,
      postCode: addressFormData.zipCode,
      city: addressFormData.city,
      country: addressFormData.country,
    };

    dispatch(validateZipCode(address.postCode))
      .unwrap()
      .then((validationResult) => {
        if (validationResult?.enabled) {
          trackEvent({ category: 'View', action: 'webapp:insuranceSelection_navigate_continue' });
          navigate(AppRoutes.Authentication(), { state: { insurance, address } });
        } else {
          trackEvent({
            category: 'View',
            action: 'webapp:insuranceSelection_navigate_decline',
            name: address.postCode,
          });
          navigate(AppRoutes.OutOfReach(), { state: { insurance, address } });
        }
      })
      .catch((e) => {
        trackEvent({ category: 'View', action: 'webapp:insuranceSelection_zipCodeValidation_error', name: e.message });
      });
  };

  const updateInsuranceFormData = (newInsuranceFormValues: Partial<TInsuranceFormData>) => {
    const updatedInsuranceFormData = {
      ...insuranceFormData,
      ...newInsuranceFormValues,
    };

    setInsuranceFormData(updatedInsuranceFormData);

    const isFormNotEmpty = Object.values(updatedInsuranceFormData).every((value) => !isEmpty(value));
    const isInsuranceNumberValid = INSURANCE_NUMBER_PATTERN.test(updatedInsuranceFormData.insuranceNumber);
    const isInsuranceIdentificationNumberValid = INSURANCE_IDENTIFICATION_NUMBER.test(
      updatedInsuranceFormData.insuranceIdentificationNumber
    );

    setIsInsuranceFormValid(isFormNotEmpty && isInsuranceNumberValid && isInsuranceIdentificationNumberValid);
  };

  const updateAddressFormData = (newAddressFormValues: Partial<TAddressFormData>) => {
    const updatedAddressFormData = {
      ...addressFormData,
      ...newAddressFormValues,
    };
    setAddressFormData(updatedAddressFormData);

    const isFormValid = Object.entries(updatedAddressFormData)
      .filter(([key, _]) => key !== EAddressFormInputTypes.CARE_OF)
      .every(([_, value]) => !isEmpty(value));

    setIsAddressFormValid(isFormValid);
  };

  return (
    <BookingPageContainer documentTitle={translations.documentTitle}>
      <InsuranceSelectionUi
        query={query}
        setQuery={setQuery}
        showSuggestionList={showSuggestionList}
        setShowSuggestionList={setShowSuggestionList}
        isLoadingInsurances={isFetchingInsurances}
        errors={{
          [EInsuranceSelectionError.INSURANCE_ERROR]: insuranceFetchingError,
          [EInsuranceSelectionError.VALIDATION_ERROR]: zipCodeValidationError,
        }}
        insuranceSuggestions={insuranceSuggestions}
        selectedInsurance={selectedInsurance}
        isContinueDisabled={isContinueDisabled}
        insuranceFormData={insuranceFormData}
        addressFormData={addressFormData}
        updateAddressFormData={updateAddressFormData}
        onSearchInput={handleSearchInput}
        updateInsuranceFormData={updateInsuranceFormData}
        onSelectInsurance={handleSelectInsurance}
        onGoBack={handleGoBack}
        onContinue={handleContinue}
      />
    </BookingPageContainer>
  );
};

export default InsuranceSelectionData;
